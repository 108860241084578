<template>
  <PrivacyPolicyStyled>
    <DataProtectionDeutsch v-if="selectedLanguage === 'DE'" />
    <DataProtectionEnglish v-else />
  </PrivacyPolicyStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import get from 'lodash/get'
import DataProtectionEnglish from './DataProtection/DataProtection_EN.vue'
import DataProtectionDeutsch from './DataProtection/DataProtection_DE.vue'

const PrivacyPolicyStyled = styled('div')`
  padding: 1rem;
`
export default {
  inject: ['uiSettings'],
  components: {
    PrivacyPolicyStyled,
    DataProtectionEnglish,
    DataProtectionDeutsch,
  },
  computed: {
    selectedLanguage() {
      return get(this.uiSettings, 'language', 'EN')
    },
  },
}
</script>
