<template>
  <DataProtectionStyled>
    <div class="main">
      <section>
        <strong>1. Introduction</strong>
        <p>
          We would like to use the information below to provide you &quot;data subject&quot; with an overview of our processing of your
          personal data and your rights under data protection law. It is generally possible to use our website without entering personal
          data. However, if you wish to make use of special services offered by our company through our website, it may be necessary to
          process personal data. If it is necessary to process personal data and there is no legal basis for such processing, we will
          generally obtain your consent.
        </p>
        <p>
          Personal data, such as your name, address or email address, is always processed in accordance with the EU General Data Protection
          Regulation (GDPR) and in accordance with the country-specific data protection regulations applicable to the &quot;talpasolutions
          GmbH&quot;. The aim of this Privacy Notice is to inform you about the scope and purpose of the personal data we collect, use and
          process.
        </p>
        <p>
          As the data controller, we have implemented numerous technical and organisational measures to ensure the most complete possible
          protection of the personal data processed via this website. Nevertheless, Internet-based data transmissions can in principle have
          security gaps so that absolute protection cannot be guaranteed. For this reason, you are free to submit personal data on
          alternative ways, such as by phone or by post to us.
        </p>
      </section>
      <section>
        <strong>2. Data controller</strong>
        <p>The data controller, as defined by the GDPR, is:</p>
        <p>talpasolutions GmbH</p>
        <p>Bismarckstr. 57, 45128 Essen, Deutschland</p>
        <p>Phone: 0201 - 822768521</p>
        <p>Data controller&rsquo;s representative: Sebastian-Friedrich Kowitz, Philipp Lorenz</p>
      </section>
      <section>
        <strong>3. Data protection officer</strong>
        <p>You can reach the data protection officer as follows:</p>
        <p>Bj&ouml;rn Leineweber</p>
        <p>Phone: 0201 - 890 66 123</p>
        <p>Email: leineweber@datenschutz-ruhr.de</p>
        <p>
          You may contact our data protection officer directly at any time if you have any questions or suggestions regarding data
          protection.
        </p>
      </section>
      <section>
        <strong>4. Definitions</strong>
        <p>
          This Privacy Notice is based on the terminology used by the European legislature and legislature in the adoption of the General
          Data Protection Regulation (GDPR). Our privacy policy should be easy to read and understand, both for the public and for our
          customers and business partners. To ensure this, we would like to explain in advance the terminology used. Among other things, we
          use the following terms in this privacy policy.
        </p>
        <p>We use the following terms in this Privacy Notice, among others:</p>
        <div class="sub-content">
          <strong>1. Personal data</strong>
          <p>
            Personal data means any information relating to an identified or identifiable natural person. An identifiable natural person is
            one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification
            number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental,
            economic, cultural or social identity of that natural person.
          </p>
          <strong>2. Data subject</strong>
          <p>
            A data subject is any identified or identifiable natural person whose personal data is processed by the data controller (our
            company).
          </p>
          <strong>3. Processing</strong>
          <p>
            Processing means any operation or set of operations which is performed on personal data or on sets of personal data, whether or
            not by automated means, such as collection, recording, organisation, structuring, storage, adaptation or alteration, retrieval,
            consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination,
            restriction, erasure or destruction.
          </p>
          <strong>4. Restriction to processing</strong>
          <p>Restriction to processing means marking stored personal data with the aim of limiting its processing in future.</p>
          <strong>5. Profiling</strong>
          <p>
            Profiling means any form of automated processing of personal data consisting of the use of personal data to evaluate certain
            personal aspects relating to a natural person, in particular to analyse or predict aspects concerning that natural person&apos;s
            performance at work, economic situation, health, personal preferences, interests, reliability, behaviour, location or movements.
          </p>
          <strong>6. Pseudonymisation</strong>
          <p>
            Pseudonymisation is the processing of personal data in such a way that the data can no longer be assigned to a specific data
            subject without additional information being provided, given that such additional information is kept separate and subject to
            appropriate technical and organisational measures that ensure that personal data cannot be attributed to an identified or
            identifiable natural person.
          </p>
          <strong>7. Data processor</strong>
          <p>
            The processor is a natural or legal person, public authority, agency or other body which processes personal data on behalf of
            the controller.
          </p>
          <strong>8. Recipient</strong>
          <p>
            Recipient means a natural or legal person, public authority, agency or another body, to which the personal data is disclosed,
            whether a third party or not. However, public authorities which may receive personal data in the framework of a particular
            inquiry in accordance with Union or Member State law shall not be regarded as recipients.
          </p>
          <strong>9. Third parties</strong>
          <p>
            Third party means a natural or legal person, public authority, agency or body other than the data subject, controller, processor
            and persons who, under the direct authority of the controller or processor, are authorised to process personal data.
          </p>
          <strong>10. Consent</strong>
          <p>
            Consent is any freely given, specific, informed and unambiguous indication of the data subject&rsquo;s wishes by which he or
            she, by a statement or by a clear affirmative action, signifies agreement to the processing of personal data relating to him or
            her.
          </p>
        </div>
      </section>
      <section>
        <strong>5. Legal basis for processing</strong>
        <p>
          Article 6 Paragraph 1(a) GDPR serves as our company&rsquo;s legal basis for processing operations in which we obtain consent for a
          specific processing purpose.
        </p>
        <p>
          If the processing of personal data is necessary for the performance of a contract to which you are a party, as is the case, for
          example, with processing operations that are necessary for the delivery of goods or the provision of other services or
          consideration, processing is based on Article 6 Paragraph 1(b) GDPR. The same applies to those processing operations required to
          carry out pre-contractual measures, such as in cases of queries regarding our products or services.
        </p>
        <p>
          If our company is subject to a legal obligation requiring the processing of personal data, such as for the fulfilment of tax
          obligations, processing is based on Article 6 Paragraph 1(c) GDPR.
        </p>
        <p>
          In rare cases, processing of personal data may be necessary to protect the vital interests of the data subject or of another
          natural person. This would be the case, for example, if someone visiting our business were to be injured and their name, age,
          health insurance data or other vital information needed to be disclosed to a doctor, hospital or other third party. Processing
          would then be based on Article 6 Paragraph 1(d) GDPR.
        </p>
        <p>
          Finally, processing operations could be based on Article 6 Paragraph 1(f) GDPR. Processing operations not based on any of the
          above-mentioned legal bases may be carried out on the basis of Article 6 Paragraph 1(f) GDPR if processing is necessary to
          safeguard the legitimate interests of our company or those of a third party, provided the interests and fundamental rights and
          freedoms of the data subject do not take precedence. We are permitted to engage in such processing operations in particular
          because they have been specifically mentioned in European law. In this respect, the legislature took the view that a legitimate
          interest could be assumed if you are a customer of our company (Recital 47 Sentence 2 GDPR).
        </p>
      </section>
      <section>
        <strong>6. Disclosure of data to third parties</strong>
        <p>Your personal data will not be sent to third parties for purposes other than those listed below.</p>
        <p>We will disclose your personal data to third parties if:</p>
        <div class="sub-content">
          <p>1. you have expressly consented to this pursuant to Article 6 Paragraph 1 Sentence 1(a) GDPR,</p>
          <p>
            2. disclosure under Article 6 Paragraph 1 Sentence 1(f) GDPR is permitted to safeguard our legitimate interests and there is no
            reason to assume that you have an overriding legitimate interest in not disclosing your data,
          </p>
          <p>
            3. in the event that there is a legal obligation to disclose your data pursuant to Article 6 Paragraph 1 Sentence 1(c) GDPR and
          </p>
          <p>
            4. if this is legally permissible and necessary for the performance of our contract with you pursuant to Article 6 Paragraph 1
            Sentence 1(b).
          </p>
        </div>
        <p>
          To protect your data and if necessary enable us to transfer data to third countries (outside the EU), we have concluded data
          processing agreements (&quot;Data Processing Agreement&quot;) based on the standard contractual clauses of the European
          Commission.
        </p>
      </section>
      <section>
        <strong>7. Technology</strong>
        <div class="sub-content">
          <br />
          <strong>7.1 SSL/TLS encryption</strong>
          <p>
            This site uses SSL or TLS encryption to <strong>ensure</strong> the security of data processing and to protect the transmission
            of confidential content, such as orders, login details or contact requests that you send to us as the website operator. You can
            recognise an encrypted connection by your browser&rsquo;s address bar reading &quot;https://&quot; instead of
            &quot;http://&quot; and the lock symbol in the browser bar.
          </p>
          <p>We use this technology to protect your transmitted data.</p>

          <strong>7.2 Data collection when visiting the website</strong>
          <p>
            If you only use our website for informational purposes, i.e. if you do not register or otherwise provide us with information, we
            only collect the data your browser sends our server (in what is known as &quot;server log files&quot;). Our website collects a
            range of general data and information each time you access a website or an automated system. This general data and information
            is stored in the server&rsquo;s log files. It may be colltected
          </p>
          <div class="sub-content">
            <p>1. the types and versions used,</p>
            <p>2. the operating system used by the accessing system,</p>
            <p>3. the website from which an accessing system accesses our website (called a referrer),</p>
            <p>4. the sub-pages accessed via an accessing system on our website,</p>
            <p>5. the date and time the website is accessed,</p>
            <p>6. a truncated internet protocol address (anonymised IP address) and</p>
            <p>7. the accessing system&apos;s internet service provider.</p>
          </div>
          <p>No conclusions are drawn about you when using this general data and information. Instead, this information is needed</p>
          <div class="sub-content">
            <p>1. to properly deliver our website content,</p>
            <p>2. to optimise the content of our website as well as to advertise it,</p>
            <p>3. to ensure the continued functioning of our IT systems and our website&rsquo;s technology</p>
            <p>
              4. as well as to provide the information necessary for law enforcement authorities to prosecute in the event of a cyber
              attack.
            </p>
          </div>
          <p>
            This collected data and information is therefore statistically analysed and further analysed by us with the aim of increasing
            data protection and data security within our company to ultimately ensure an optimum level of protection for the personal data
            being processed by us. The anonymous data from the server log files is stored separately from all personal data provided by a
            data subject.
          </p>
          <p>
            The legal basis for data processing is Article 6 Paragraph 1 Sentence 1(f) GDPR. Our legitimate interest is based on the
            purposes listed above for the collection of data.
          </p>
        </div>
      </section>
      <section>
        <strong>8. Cookies</strong>
        <div class="sub-content">
          <br />
          <strong>8.1 General information about cookies</strong>
          <p>
            We use cookies on our website. Cookies are small files that are automatically created by your browser and stored on your IT
            system (laptop, tablet, smartphone, etc.) when you visit our website.
          </p>
          <p>
            Information generated from the specific device used is stored in cookies. This does not mean, however, that we will gain
            immediate knowledge of your identity.
          </p>
          <p>
            The use of cookies helps us make it more convenient for you to use our website. For example, we use session cookies to detect
            whether you have already visited individual pages on our website. These are erased automatically when you leave our website.
          </p>
          <p>
            We also use temporary cookies to optimise user-friendliness. These cookies are stored on your device for a specific period of
            time. If you return to our website to use our services, cookies allow us to automatically recognise that you have visited our
            website previously and remember the inputs and settings you have made so that you do not have to enter them again.
          </p>
          <p>
            We also use cookies to statistically record the use of our website and analyse it for the purpose of optimising our services.
            These cookies allow us to automatically recognise that you have already visited our website when you visit our website again.
            These cookies are automatically erased after a defined period of time.
          </p>
          <strong>8.2 Legal basis for the use of cookies</strong>
          <p>
            The data processed by cookies, which are required for the proper functioning of the website, are to safeguard our legitimate
            interests pursuant to Article 6 Paragraph 1 Sentence 1(f) GDPR.
          </p>
          <p>
            For all other cookies you have given your consent to this through our opt-in cookie banner in accordance with Article 6
            Paragraph 1(a) GDPR.
          </p>
        </div>
      </section>
      <section>
        <strong>9. Contents of our website</strong>
        <div class="sub-content">
          <br />
          <strong>9.1 Registering as a user</strong>
          <p>You have the option to register on our website by providing personal data.</p>
          <p>
            The input screen used to register in each case determines what personal data is shared with us. The personal data you enter will
            be collected and stored exclusively for internal use by us and for our own purposes. We may arrange for data to be shared with
            one or more data processors, such as a parcel service, which will also use your personal data solely for internal purposes
            attributable to us.
          </p>
          <p>
            When you register on our website, the IP address assigned by your Internet Service Provider (ISP) and the date and time of
            registration are also stored. This is done only for the purpose of preventing our services from being misused. If necessary,
            this data may be used to clarify the situation surrounding any crimes committed. In this respect, the storage of this data is
            necessary for our security. This data will not be disclosed to third parties unless there is a legal obligation to do so or the
            data is used for criminal prosecution.
          </p>
          <p>
            Your registration, including the voluntary entry of personal data, also allows us to offer you content or services which, due to
            their nature, may only be offered to registered users. Registered persons are free to modify the personal data they provided
            during the registration process at any time or have it completely erased from our database.
          </p>
          <p>
            We will provide you with information at any time on request as to what personal data is stored about you. We will also rectify
            or erase delete personal data at your request, unless legal retention obligations to the contrary are in place. Data subjects
            may contact the data protection officer named in this Privacy Notice and all other employees for this purpose.
          </p>
          <p>
            Your data is processed in the interests of simple, convenient use of our website. This constitutes a legitimate interest as
            defined by Article 6 Paragraph 1(f) GDPR.
          </p>

          <strong>9.2 Data processing when opening a customer account and for contract execution</strong>
          <p>
            Pursuant to Article 6 Paragraph 1(b) GDPR, personal data is collected and processed if you provide it to us for the execution of
            a contract or when opening a customer account. The data collected is shown in each input form. You may erase your customer
            account at any time by sending a message to the data controller&rsquo;s address as stated above. We store and use the data you
            provide to execute contracts. After complete execution of the contract or erasure of your customer account, your data will be
            blocked, taking into account tax and commercial retention periods, and erased once these periods have expired unless you have
            expressly consented to the further use of your data or we are legally permitted to further use your data, about which we will
            inform you below.
          </p>

          <strong>9.3 Contact/contact form</strong>
          <p>
            Personal data is collected when you contact us (e.g. using our contact form or by email). If you use a contact form to get in
            touch with us, the contact form you use will indicate the data being collected. This data is stored and used exclusively for the
            purpose of responding to your query or establishing contact and the associated technical administration. The legal basis for
            data processing is our legitimate interest in responding to your request pursuant to Article 6 Paragraph 1(f)f GDPR. If the aim
            of you contacting us is to conclude a contract, processing is also legally based on Article 6 Paragraph 1(b) GDPR. Your data
            will be erased once we have finished processing your query. This is the case when it can be inferred from the circumstances that
            the relevant facts have been clarified in a conclusive manner and there are no statutory retention obligations in place that
            prevent its erasure.
          </p>

          <strong>9.4 Services/digital goods</strong>
          <p>
            We only send personal data to third parties where necessary as part of contract execution, for example to the bank entrusted
            with processing payment.
          </p>
          <p>
            No data is otherwise sent unless you have expressly agreed to this. Your data will not be disclosed to third parties without
            your express consent, for example for advertising purposes.
          </p>
          <p>
            The basis for data processing is Article 6 Paragraph 1(b) GDPR, which permits the processing of data for the fulfilment of a
            contract or pre-contractual measures.
          </p>
        </div>
      </section>
      <section>
        <strong>10. Web analytics</strong>
        <div class="sub-content">
          <br />
          <strong>10.1 Google Analytics</strong>
          <p>
            We use Google Analytics, a web analytics service provided by Google Ireland Limited (https://www.google.com/about/) (Gordon
            House, Barrow Street, Dublin 4, Ireland; hereinafter referred to as &quot;Google&quot;), on our website. As part of this,
            pseudonymised user profiles are created and cookies (see the section on &quot;Cookies&quot;) are used. The information generated
            by the cookie about your use of this website, such as your browser
          </p>
          <div class="sub-content">
            <p>1. browser type/version</p>
            <p>2. operating system</p>
            <p>3. referrer URL (website previously visited), host</p>
            <p>4. name of the accessing computer (IP address) and</p>
            <p>5. time of server request,</p>
          </div>
          <p>
            is transmitted to a Google server in the US and stored there. This information is used to evaluate your use of this website, to
            compile reports on the website activities, and to perform further services linked to website and internet use for market
            research purposes and to tailor the design of this website. This information may also be sent to third parties if this is
            legally required or if third parties process this data on behalf of Google. Under no circumstances will your IP address be
            associated with any other data. IP addresses are anonymised so that it is not possible to assign them to individuals (known as
            IP masking).
          </p>
          <p>
            You may refuse the use of cookies by selecting the appropriate settings on your browser; however, we would point out that this
            may result in you not being able to use all the features of this website.
          </p>
          <p>These processing operations only take place if express consent is granted in accordance with Article 6 Paragraph 1(a) GDPR.</p>
          <p>
            You can also prevent the data generated by the cookie about your use of the website (including your IP address) from being sent
            to and processed by Google by downloading and installing the available browser add-on
            (https://tools.google.com/dlpage/gaoptout&rsquo;hl=en).
          </p>
          <p>
            As an alternative to the browser add-on, especially for browsers on mobile devices, you can also prevent Google Analytics from
            capturing data by clicking on the following link: Deactivate Google Analytics. This sets an opt-out cookie that prevents the
            future collection of your data when visiting this website. The opt-out cookie is only valid on this browser and only for our
            website and is stored on your device. If you erase the cookies stored for this browser, you will need to reset the opt-out
            cookie.
          </p>
          <p>
            Additional information on data protection with respect to Google Analytics is available on the Google Analytics website in the
            help section (https://support.google.com/analytics/answer/6004245&rsquo;hl=en).
          </p>

          <strong>10.2 Matomo</strong>
          <p>
            On this website we have integrated the component Matomo of the supplier InnoCraft Ltd., 150 Willis St, 6011 Wellington, New
            Zealand.
          </p>
          <p>
            Matomo is a software tool for web analysis, ie for the collection, collection and analysis of data on the behavior of visitors
            to websites. Among other things, data are collected on which website an affected person has come to a website (so-called
            referrer), which subpages of the website were accessed or how often and for which length of stay a subpage was viewed. This is
            used to optimize the website and cost-benefit analysis of Internet advertising.
          </p>
          <p>
            The purpose of the Matomo component is to analyse the flow of visitors to our website. Among other things, we use the data and
            information obtained to evaluate the use of this website and to compile online reports for us that show activity on our website.
          </p>
          <p>
            Matomo sets a cookie on your IT system. By setting the cookie, we are enabled to analyze the use of our website. Each time you
            visit one of the pages on this website, the Internet browser on your IT system automatically causes the Matomo component to
            submit data to our server for online analysis. In the course of this technical process, we gain knowledge of personal data, such
            as the IP address of the person concerned, which among other things serves to help us understand the origin of visitors and
            clicks.
          </p>
          <p>
            The cookie stores personal information, such as access time, the place from which access was made, and the frequency of visits
            to our website. Each time you visit our website, this personal information, including the IP address of the Internet connection
            you use, is transmitted to our server. These personal data are stored by us. We do not share this personal information with
            third parties.
          </p>
          <p>
            You can prevent the setting of cookies through our website at any time by means of a corresponding setting of the Internet
            browser used and thus permanently contradict the setting of cookies. Such a setting of the Internet browser used would also
            prevent Matomo from setting a cookie on your IT system. In addition, a cookie already set by Matomo can be deleted at any time
            via an internet browser or other software programs.
          </p>
          <p>
            You also have the option of objecting to and preventing the collection of the data generated by Matomo relating to the use of
            this website. You need to set an opt-out cookie to do this. If your IT system is later erased, formatted or reinstalled, the
            data subject will need set an opt-out cookie again. However, setting an opt-out cookie may result in you being unable to make
            full use of our website.
          </p>
          <p>These processing operations only take place if express consent is granted in accordance with Article 6 Paragraph 1(a) GDPR.</p>
          <p>
            Please note, however, that if you refuse your consent, we will still use Matomo. In this case, however, your data will be
            recorded completely anonymously. There is no tracking across multiple websites, the fingerprints, i.e. the data they leave
            behind, are encrypted with a new key every day and the data is not used for any purposes other than the mere analysis of
            anonymous user behavior. In this case, in particular, no cookie is left on your computer. You can see details here:
            https://matomo.org/cookie-consent-banners/. In this case, use of the data is made under legitimate interest, Art. 6 lit. f)
            GDPR.<span class="Apple-converted-space">&nbsp;</span>
          </p>
          <p>Further information and the applicable data protection provisions of Matomo can be found at https://matomo.org/privacy/.</p>
        </div>
      </section>
      <section>
        <strong>11. Your rights as a data subject</strong>
        <div class="sub-content">
          <br />
          <strong>11.1 Right to confirmation</strong>
          <p>You have the right to request confirmation from us as to whether personal data relating to you will be processed.</p>

          <strong>11.2 Right to information (Article 15 GDPR)</strong>
          <p>
            You have the right to obtain information about the personal data stored about you at any time, free of charge, as well as the
            right to access a copy of such data from us, in accordance with the statutory provisions.
          </p>

          <strong>11.3 Right to rectification (Article 16 GDPR)</strong>
          <p>
            You have the right to request the immediate rectification of incorrect personal data relating to yourself. Furthermore, the data
            subject has the right to request the completion of incomplete personal data, taking into account the purposes of the processing.
          </p>

          <strong>11.4 Erasure (Article 17 GDPR)</strong>
          <p>
            You have the right to demand that we erase the personal data relating to you be deleted without delay, provided that one of the
            reasons provided by law applies and if processing or further storage is not required.
          </p>

          <strong>11.5 Restriction to processing (Article 18 GDPR)</strong>
          <p>You have the right to request that we restrict the processing of your data if one of the legal requirements is met.</p>

          <strong>11.6 Data transferability (Article 20 GDPR)</strong>
          <p>
            You have the right obtain personal data relating to you that you provided us in a structured, commonly used and machine-readable
            format. You also have the right to transfer this data to another controller without hindrance by us, to whom the personal data
            was provided, provided that the processing is based on the consent pursuant to Article 6 Paragraph 1(a) GDPR or Article 9
            Paragraph 2(a) GDPR or on a contract pursuant to Article 6 Paragraph 1(b) GDPR, and the data are processed using automated
            procedures, unless processing is necessary to complete a task, is in the public interest or is carried out in the exercise of an
            official authority assigned to us.
          </p>
          <p>
            Furthermore, when exercising your right to data transferability pursuant to Article 20 Paragraph 1 GDPR, you have the right to
            have personal data transferred directly from one controller to another, provided this is technically feasible and does not
            impede the rights and freedoms of other persons.
          </p>

          <strong>11.7 Objection (Article 21 GDPR)</strong>
          <p>
            You have the right to lodge an objection to the processing of personal data relating to you for reasons relating to your
            particular situation where this is done on the basis of Article 6 Paragraph 1(e) (data processing in the public interest) or (f)
            (data processing on the basis of the weighing of legitimate interests) GDPR.
          </p>
          <p>This also applies to profiling based on these provisions pursuant to Article 4 Number 4 GDPR.</p>
          <p>
            Should you lodge an objection, we will no longer process your personal data unless we can demonstrate compelling and legitimate
            reasons for such processing that outweigh your interests, rights and freedoms, or where processing serves the assertion,
            exercise or defence of legal claims.
          </p>
          <p>
            In individual cases, we process your personal data for direct marketing purposes. You have the right to object at any time to
            the processing of personal data for the purpose of such advertising. This also applies to profiling where this is connected to
            this kind of direct marketing. Should you object to the processing of your data for direct marketing purposes, we will no longer
            process your personal data for this purpose.
          </p>
          <p>
            In addition, you have the right to object to our processing of your personal data for scientific or historical research purposes
            or for statistical purposes pursuant to Article 89 Paragraph 1 GDPR for reasons arising from your particular situation, unless
            such processing is necessary for the performance of a task in the public interest.
          </p>
          <p>
            You are free to exercise your right to lodge an objection in relation to the use of information society services, Directive
            2002/58/EC notwithstanding, by means of automated procedures using technical specifications.
          </p>

          <strong>11.8 Revocation of consent regarding data protection</strong>
          <p>You have the right to revoke any consent to the processing of personal data at any time with future effect.</p>

          <strong>11.9 Lodging a complaint with a supervisory authority</strong>
          <p>
            You have the right to complain to a supervisory authority responsible for data protection about our processing of personal data.
          </p>
        </div>
      </section>
      <section>
        <strong>12. Routine storage, erasure and blocking of personal data</strong>
        <p>
          We process and store your personal data only for the period of time necessary to meet the storage purpose or as required by the
          legal provisions to which our company is subject.
        </p>
        <p>
          If the storage purpose no longer applies or if a required retention period expires, personal data will be routinely blocked or
          erased in accordance with the statutory provisions.
        </p>
      </section>
      <section>
        <strong>13. Duration of storage of personal data</strong>
        <p>
          The criterion for the duration of the retention of personal data is the respective legal retention period. Once this period
          expires, the data in question will be routinely erased, provided it is no longer required for the fulfilment or initiation of the
          contract.
        </p>
      </section>
      <section>
        <strong>14. Version and amendments to the Privacy Notice</strong>
        <p>This Privacy Notice is currently valid and was last updated on February 2024.</p>
        <p>
          It may be necessary for us to amend this Privacy Notice in the process of further developing our website and the services we offer
          through our website or due to changes in legal or regulatory requirements. You can view and print our current Privacy Notice on
          the website at any time by visiting <a href="https://cockpit.talpa.io/">cockpit</a>.
        </p>
        <p>
          This privacy statement has been prepared with the assistance of the privacy software:
          <a href="https://www.audatis-manager.de">audatis MANAGER.</a>
        </p>
      </section>
    </div>
  </DataProtectionStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

const DataProtectionStyled = styled('div')`
  .main {
    padding: 1rem;
    > section {
      padding-bottom: 0.5rem;
    }
    .sub-content {
      padding: 0 1.2rem;
    }
    p {
      padding: 0 1.2rem;
    }
  }
`

export default {
  components: {
    DataProtectionStyled,
  },
}
</script>
