<template>
  <DataProtectionStyled>
    <div class="main">
      <section>
        <strong>1. Einleitung</strong>
        <p>
          Mit den folgenden Informationen m&ouml;chten wir Ihnen als &quot;betroffener Person&quot; einen &Uuml;berblick &uuml;ber die
          Verarbeitung Ihrer personenbezogenen Daten durch uns und Ihre Rechte aus den Datenschutzgesetzen geben. Eine Nutzung unserer
          Internetseiten ist grunds&auml;tzlich ohne Eingabe personenbezogener Daten m&ouml;glich. Sofern Sie besondere Services unseres
          Unternehmens &uuml;ber unsere Internetseite in Anspruch nehmen m&ouml;chten, k&ouml;nnte jedoch eine Verarbeitung
          personenbezogener Daten erforderlich werden. Ist die Verarbeitung personenbezogener Daten erforderlich und besteht f&uuml;r eine
          solche Verarbeitung keine gesetzliche Grundlage, holen wir generell eine Einwilligung von Ihnen ein.
        </p>
        <p>
          Die Verarbeitung von personenbezogenen Daten, beispielsweise Ihres Namens, der Anschrift oder E-Mail-Adresse, erfolgt stets im
          Einklang mit der Datenschutz-Grundverordnung (DS-GVO) und in &Uuml;bereinstimmung mit den f&uuml;r die &quot;talpasolutions
          GmbH&quot; geltenden landesspezifischen Datenschutzbestimmungen. Mittels dieser Datenschutzerkl&auml;rung m&ouml;chten wir Sie
          &uuml;ber Umfang und Zweck der von uns erhobenen, genutzten und verarbeiteten personenbezogenen Daten informieren.
        </p>
        <p>
          Wir haben als f&uuml;r die Verarbeitung Verantwortliche zahlreiche technische und organisatorische Ma&szlig;nahmen umgesetzt, um
          einen m&ouml;glichst l&uuml;ckenlosen Schutz der &uuml;ber diese Internetseite verarbeiteten personenbezogenen Daten
          sicherzustellen. Dennoch k&ouml;nnen internetbasierte Daten&uuml;bertragungen grunds&auml;tzlich Sicherheitsl&uuml;cken aufweisen,
          sodass ein absoluter Schutz nicht gew&auml;hrleistet werden kann. Aus diesem Grund steht es Ihnen frei, personenbezogene Daten
          auch auf alternativen Wegen, beispielsweise telefonisch oder per Post, an uns zu &uuml;bermitteln.
        </p>
      </section>
      <section>
        <strong>2. Verantwortlicher</strong>
        <p>Verantwortlicher im Sinne der DS-GVO ist die:</p>
        <p>talpasolutions GmbH</p>
        <p>Bismarckstr. 57, 45128 Essen, Deutschland</p>
        <p>Telefon: 0201 - 822768521</p>
        <p>Vertreter des Verantwortlichen: Sebastian-Friedrich Kowitz, Philipp Lorenz</p>
      </section>
      <section>
        <strong>3. Datenschutzbeauftragter</strong>
        <p>Den Datenschutzbeauftragten erreichen Sie wie folgt:</p>
        <p>Bj&ouml;rn Leineweber</p>
        <p>Telefon: 0201 - 890 66 123</p>
        <p>E-Mail: leineweber@datenschutz-ruhr.de</p>
        <p>
          Sie k&ouml;nnen sich jederzeit bei allen Fragen und Anregungen zum Datenschutz direkt an unseren Datenschutzbeauftragten wenden.
        </p>
      </section>
      <section>
        <strong>4. Begriffsbestimmungen</strong>
        <p>
          Die Datenschutzerkl&auml;rung beruht auf den Begrifflichkeiten, die durch den Europ&auml;ischen Richtlinien- und Verordnungsgeber
          beim Erlass der Datenschutz-Grundverordnung (DS-GVO) verwendet wurden. Unsere Datenschutzerkl&auml;rung soll sowohl f&uuml;r die
          &Ouml;ffentlichkeit als auch f&uuml;r unsere Kunden und Gesch&auml;ftspartner einfach lesbar und verst&auml;ndlich sein. Um dies
          zu gew&auml;hrleisten, m&ouml;chten wir vorab die verwendeten Begrifflichkeiten erl&auml;utern.
        </p>
        <p>Wir verwenden in dieser Datenschutzerkl&auml;rung unter anderem die folgenden Begriffe:</p>
        <div class="sub-content">
          <strong>1. Personenbezogene Daten</strong>
          <p>
            Personenbezogene Daten sind alle Informationen, die sich auf eine identifizierte oder identifizierbare nat&uuml;rliche Person
            beziehen. Als identifizierbar wird eine nat&uuml;rliche Person angesehen, die direkt oder indirekt, insbesondere mittels
            Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung oder zu einem oder
            mehreren besonderen Merkmalen, die Ausdruck der physischen, physiologischen, genetischen, psychischen, wirtschaftlichen,
            kulturellen oder sozialen Identit&auml;t dieser nat&uuml;rlichen Person sind, identifiziert werden kann.
          </p>
          <strong>2. Betroffene Person</strong>
          <p>
            Betroffene Person ist jede identifizierte oder identifizierbare nat&uuml;rliche Person, deren personenbezogene Daten von dem
            f&uuml;r die Verarbeitung Verantwortlichen (unser Unternehmen) verarbeitet werden.
          </p>
          <strong>3. Verarbeitung</strong>
          <p>
            Verarbeitung ist jeder mit oder ohne Hilfe automatisierter Verfahren ausgef&uuml;hrte Vorgang oder jede solche Vorgangsreihe im
            Zusammenhang mit personenbezogenen Daten wie das Erheben, das Erfassen, die Organisation, das Ordnen, die Speicherung, die
            Anpassung oder Ver&auml;nderung, das Auslesen, das Abfragen, die Verwendung, die Offenlegung durch &Uuml;bermittlung,
            Verbreitung oder eine andere Form der Bereitstellung, den Abgleich oder die Verkn&uuml;pfung, die Einschr&auml;nkung, das
            L&ouml;schen oder die Vernichtung.
          </p>
          <strong>4. Einschr&auml;nkung der Verarbeitung</strong>
          <p>
            Einschr&auml;nkung der Verarbeitung ist die Markierung gespeicherter personenbezogener Daten mit dem Ziel, ihre k&uuml;nftige
            Verarbeitung einzuschr&auml;nken.
          </p>
          <strong>5. Profiling</strong>
          <p>
            Profiling ist jede Art der automatisierten Verarbeitung personenbezogener Daten, die darin besteht, dass diese personenbezogenen
            Daten verwendet werden, um bestimmte pers&ouml;nliche Aspekte, die sich auf eine nat&uuml;rliche Person beziehen, zu bewerten,
            insbesondere, um Aspekte bez&uuml;glich Arbeitsleistung, wirtschaftlicher Lage, Gesundheit, pers&ouml;nlicher Vorlieben,
            Interessen, Zuverl&auml;ssigkeit, Verhalten, Aufenthaltsort oder Ortswechsel dieser nat&uuml;rlichen Person zu analysieren oder
            vorherzusagen.
          </p>
          <strong>6. Pseudonymisierung</strong>
          <p>
            Pseudonymisierung ist die Verarbeitung personenbezogener Daten in einer Weise, auf welche die personenbezogenen Daten ohne
            Hinzuziehung zus&auml;tzlicher Informationen nicht mehr einer spezifischen betroffenen Person zugeordnet werden k&ouml;nnen,
            sofern diese zus&auml;tzlichen Informationen gesondert aufbewahrt werden und technischen und organisatorischen Ma&szlig;nahmen
            unterliegen, die gew&auml;hrleisten, dass die personenbezogenen Daten nicht einer identifizierten oder identifizierbaren
            nat&uuml;rlichen Person zugewiesen werden.
          </p>
          <strong>7. Auftragsverarbeiter</strong>
          <p>
            Auftragsverarbeiter ist eine nat&uuml;rliche oder juristische Person, Beh&ouml;rde, Einrichtung oder andere Stelle, die
            personenbezogene Daten im Auftrag des Verantwortlichen verarbeitet.
          </p>
          <strong>8. Empf&auml;nger</strong>
          <p>
            Empf&auml;nger ist eine nat&uuml;rliche oder juristische Person, Beh&ouml;rde, Einrichtung oder andere Stelle, der
            personenbezogene Daten offengelegt werden, unabh&auml;ngig davon, ob es sich bei ihr um einen Dritten handelt oder nicht.
            Beh&ouml;rden, die im Rahmen eines bestimmten Untersuchungsauftrags nach dem Unionsrecht oder dem Recht der Mitgliedstaaten
            m&ouml;glicherweise personenbezogene Daten erhalten, gelten jedoch nicht als Empf&auml;nger.
          </p>
          <strong>9. Dritter</strong>
          <p>
            Dritter ist eine nat&uuml;rliche oder juristische Person, Beh&ouml;rde, Einrichtung oder andere Stelle au&szlig;er der
            betroffenen Person, dem Verantwortlichen, dem Auftragsverarbeiter und den Personen, die unter der unmittelbaren Verantwortung
            des Verantwortlichen oder des Auftragsverarbeiters befugt sind, die personenbezogenen Daten zu verarbeiten.
          </p>
          <strong>10. Einwilligung</strong>
          <p>
            Einwilligung ist jede von der betroffenen Person freiwillig f&uuml;r den bestimmten Fall in informierter Weise und
            unmissverst&auml;ndlich abgegebene Willensbekundung in Form einer Erkl&auml;rung oder einer sonstigen eindeutigen
            best&auml;tigenden Handlung, mit der die betroffene Person zu verstehen gibt, dass Sie mit der Verarbeitung der Sie betreffenden
            personenbezogenen Daten einverstanden ist.
          </p>
        </div>
      </section>
      <section>
        <strong>5. Rechtsgrundlage der Verarbeitung</strong>
        <p>
          Art. 6 Abs. 1 lit. a DS-GVO (i.V.m. &sect; 15 Abs. 3 TMG) dient unserem Unternehmen als Rechtsgrundlage f&uuml;r
          Verarbeitungsvorg&auml;nge, bei denen wir eine Einwilligung f&uuml;r einen bestimmten Verarbeitungszweck einholen.
        </p>
        <p>
          Ist die Verarbeitung personenbezogener Daten zur Erf&uuml;llung eines Vertrags, dessen Vertragspartei Sie sind, erforderlich, wie
          dies beispielsweise bei Verarbeitungsvorg&auml;ngen der Fall ist, die f&uuml;r eine Lieferung von Waren oder die Erbringung einer
          sonstigen Leistung oder Gegenleistung notwendig sind, so beruht die Verarbeitung auf Art. 6 Abs. 1 lit. b DS-GVO. Gleiches gilt
          f&uuml;r solche Verarbeitungsvorg&auml;nge die zur Durchf&uuml;hrung vorvertraglicher Ma&szlig;nahmen erforderlich sind, etwa in
          F&auml;llen von Anfragen zu unseren Produkten oder Leistungen.
        </p>
        <p>
          Unterliegt unser Unternehmen einer rechtlichen Verpflichtung durch welche eine Verarbeitung von personenbezogenen Daten
          erforderlich wird, wie beispielsweise zur Erf&uuml;llung steuerlicher Pflichten, so basiert die Verarbeitung auf Art. 6 Abs. 1
          lit. c DS-GVO.
        </p>
        <p>
          In seltenen F&auml;llen k&ouml;nnte die Verarbeitung von personenbezogenen Daten erforderlich werden, um lebenswichtige Interessen
          der betroffenen Person oder einer anderen nat&uuml;rlichen Person zu sch&uuml;tzen. Dies w&auml;re beispielsweise der Fall, wenn
          ein Besucher in unserem Betrieb verletzt werden w&uuml;rde und daraufhin sein Name, sein Alter, seine Krankenkassendaten oder
          sonstige lebenswichtige Informationen an einen Arzt, ein Krankenhaus oder sonstige Dritte weitergegeben werden m&uuml;ssten. Dann
          w&uuml;rde die Verarbeitung auf Art. 6 Abs. 1 lit. d DS-GVO beruhen.
        </p>
        <p>
          Letztlich k&ouml;nnten Verarbeitungsvorg&auml;nge auf Art. 6 Abs. 1 lit. f DS-GVO beruhen. Auf dieser Rechtsgrundlage basieren
          Verarbeitungsvorg&auml;nge, die von keiner der vorgenannten Rechtsgrundlagen erfasst werden, wenn die Verarbeitung zur Wahrung
          eines berechtigten Interesses unseres Unternehmens oder eines Dritten erforderlich ist, sofern die Interessen, Grundrechte und
          Grundfreiheiten des Betroffenen nicht &uuml;berwiegen. Solche Verarbeitungsvorg&auml;nge sind uns insbesondere deshalb gestattet,
          weil sie durch den Europ&auml;ischen Gesetzgeber besonders erw&auml;hnt wurden. Er vertrat insoweit die Auffassung, dass ein
          berechtigtes Interesse anzunehmen sein k&ouml;nnte, wenn Sie ein Kunde unseres Unternehmens sind (Erw&auml;gungsgrund 47 Satz 2
          DS-GVO).
        </p>
      </section>
      <section>
        <strong>6. &Uuml;bermittlung von Daten an Dritte</strong>
        <p>
          Eine &Uuml;bermittlung Ihrer pers&ouml;nlichen Daten an Dritte zu anderen als den im Folgenden aufgef&uuml;hrten Zwecken findet
          nicht statt.
        </p>
        <p>Wir geben Ihre pers&ouml;nlichen Daten nur an Dritte weiter, wenn:</p>
        <div class="sub-content">
          <p>1. Sie Ihre nach Art. 6 Abs. 1 S. 1 lit. a DS-GVO ausdr&uuml;ckliche Einwilligung dazu erteilt haben,</p>
          <p>
            2. die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. f DS-GVO zur Wahrung unserer berechtigten Interessen zul&auml;ssig ist und kein
            Grund zur Annahme besteht, dass Sie ein &uuml;berwiegendes schutzw&uuml;rdiges Interesse an der Nichtweitergabe Ihrer Daten
            haben,
          </p>
          <p>
            3. f&uuml;r den Fall, dass f&uuml;r die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. c DS-GVO eine gesetzliche Verpflichtung besteht,
            sowie
          </p>
          <p>
            4. dies gesetzlich zul&auml;ssig und nach Art. 6 Abs. 1 S. 1 lit. b DS-GVO f&uuml;r die Abwicklung von
            Vertragsverh&auml;ltnissen mit Ihnen erforderlich ist.
          </p>
          <p>
            Um Ihre Daten zu sch&uuml;tzen und uns gegebenenfalls eine Daten&uuml;bermittlung in Drittl&auml;nder (au&szlig;erhalb der
            EU/des EWR)zu erm&ouml;glichen, haben wir Vereinbarungen zur Auftragsverarbeitung auf Grundlage der Standardvertragsklauseln der
            Europ&auml;ischen Kommission abgeschlossen.
          </p>
        </div>
      </section>
      <section>
        <strong>7. Technik</strong>
        <div class="sub-content">
          <br />
          <strong>7.1 SSL/TLS-Verschl&uuml;sselung</strong>
          <p>
            Diese Seite nutzt zur <strong>Gew&auml;hrleistung</strong> der Sicherheit der Datenverarbeitung und zum Schutz der
            &Uuml;bertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen, Login-Daten oder Kontaktanfragen, die Sie an uns als
            Betreiber senden, eine SSL-bzw. TLS-Verschl&uuml;sselung. Eine verschl&uuml;sselte Verbindung erkennen Sie daran, dass in der
            Adresszeile des Browsers statt einem &quot;http://&quot; ein &quot;https://&quot; steht und an dem Schloss-Symbol in Ihrer
            Browserzeile.
          </p>
          <p>Wir setzen diese Technologie ein, um Ihre &uuml;bermittelten Daten zu sch&uuml;tzen.</p>
          <strong>7.2 Datenerfassung beim Besuch der Internetseite</strong>
          <p>
            Bei der lediglich informatorischen Nutzung unserer Website, also wenn Sie sich nicht registrieren oder uns anderweitig
            Informationen &uuml;bermitteln, erheben wir nur solche Daten, die Ihr Browser an unseren Server &uuml;bermittelt (in sog.
            &quot;Server-Logfiles&quot;). Unsere Internetseite erfasst mit jedem Aufruf einer Seite durch Sie oder ein automatisiertes
            System eine Reihe von allgemeinen Daten und Informationen. Diese allgemeinen Daten und Informationen werden in den Logfiles des
            Servers gespeichert. Erfasst werden k&ouml;nnen die
          </p>
          <div class="sub-content">
            <p>1. verwendeten Browsertypen und Versionen,</p>
            <p>2. das vom zugreifenden System verwendete Betriebssystem,</p>
            <p>3. die Internetseite, von welcher ein zugreifendes System auf unsere Internetseite gelangt (sogenannte Referrer),</p>
            <p>4. die Unterwebseiten, welche &uuml;ber ein zugreifendes System auf unserer Internetseite angesteuert werden,</p>
            <p>5. das Datum und die Uhrzeit eines Zugriffs auf die Internetseite,</p>
            <p>6. eine gek&uuml;rzte Internet-Protokoll-Adresse (anonymisierte IP-Adresse),</p>
            <p>7. der Internet-Service-Provider des zugreifenden Systems.</p>
          </div>
          <p>
            Bei der Nutzung dieser allgemeinen Daten und Informationen ziehen wir keine R&uuml;ckschl&uuml;sse auf Ihre Person. Diese
            Informationen werden vielmehr ben&ouml;tigt, um
          </p>
          <div class="sub-content">
            <p>1. die Inhalte unserer Internetseite korrekt auszuliefern,</p>
            <p>2. die Inhalte unserer Internetseite sowie die Werbung f&uuml;r diese zu optimieren,</p>
            <p>
              3. die dauerhafte Funktionsf&auml;higkeit unserer IT-Systeme und der Technik unserer Internetseite zu gew&auml;hrleisten sowie
            </p>
            <p>
              4. um Strafverfolgungsbeh&ouml;rden im Falle eines Cyberangriffes die zur Strafverfolgung notwendigen Informationen
              bereitzustellen.
            </p>
          </div>
          <p>
            Diese erhobenen Daten und Informationen werden durch uns daher einerseits statistisch und ferner mit dem Ziel ausgewertet, den
            Datenschutz und die Datensicherheit in unserem Unternehmen zu erh&ouml;hen, um letztlich ein optimales Schutzniveau f&uuml;r die
            von uns verarbeiteten personenbezogenen Daten sicherzustellen. Die anonymen Daten der Server-Logfiles werden getrennt von allen
            durch eine betroffene Person angegebenen personenbezogenen Daten gespeichert.
          </p>
          <p>
            Die Rechtsgrundlage f&uuml;r die Datenverarbeitung ist Art. 6 Abs. 1 S. 1 lit. f DS-GVO. Unser berechtigtes Interesse folgt aus
            oben aufgelisteten Zwecken zur Datenerhebung.
          </p>
        </div>
      </section>
      <section>
        <strong>8. Cookies</strong>
        <div class="sub-content">
          <br />
          <strong>8.1 Allgemeines zu Cookies</strong>
          <p>
            Wir setzen auf unserer Internetseite Cookies ein. Hierbei handelt es sich um kleine Dateien, die Ihr Browser automatisch
            erstellt und die auf Ihrem IT-System (Laptop, Tablet, Smartphone o.&auml;.) gespeichert werden, wenn Sie unsere Seite besuchen.
          </p>
          <p>
            In dem Cookie werden Informationen abgelegt, die sich jeweils im Zusammenhang mit dem spezifisch eingesetzten Endger&auml;t
            ergeben. Dies bedeutet jedoch nicht, dass wir dadurch unmittelbar Kenntnis von Ihrer Identit&auml;t erhalten.
          </p>
          <p>
            Der Einsatz von Cookies dient einerseits dazu, die Nutzung unseres Angebots f&uuml;r Sie angenehmer zu gestalten. So setzen wir
            sogenannte Session-Cookies ein, um zu erkennen, dass Sie einzelne Seiten unserer Website bereits besucht haben. Diese werden
            nach Verlassen unserer Seite automatisch gel&ouml;scht.
          </p>
          <p>
            Dar&uuml;ber hinaus setzen wir ebenfalls zur Optimierung der Benutzerfreundlichkeit tempor&auml;re Cookies ein, die f&uuml;r
            einen bestimmten festgelegten Zeitraum auf Ihrem Endger&auml;t gespeichert werden. Besuchen Sie unsere Seite erneut, um unsere
            Dienste in Anspruch zu nehmen, wird automatisch erkannt, dass Sie bereits bei uns waren und welche Eingaben und Einstellungen
            Sie get&auml;tigt haben, um diese nicht noch einmal eingeben zu m&uuml;ssen.
          </p>
          <p>
            Zum anderen setzten wir Cookies ein, um die Nutzung unserer Website statistisch zu erfassen und zum Zwecke der Optimierung
            unseres Angebotes f&uuml;r Sie auszuwerten. Diese Cookies erm&ouml;glichen es uns, bei einem erneuten Besuch unserer Seite
            automatisch zu erkennen, dass Sie bereits bei uns waren. Diese Cookies werden nach einer jeweils definierten Zeit automatisch
            gel&ouml;scht.
          </p>
          <strong>8.2 Rechtsgrundlage f&uuml;r die Verwendung von Cookies</strong>
          <p>
            Die durch Cookies verarbeiteten Daten, die f&uuml;r die ordnungsm&auml;&szlig;ige Funktion der Webseite ben&ouml;tigt werden,
            sind damit zur Wahrung unserer berechtigten Interessen sowie der Dritter nach Art. 6 Abs. 1 S. 1 lit. f DS-GVO erforderlich.
          </p>
          <p>
            F&uuml;r alle anderen Cookies gilt, dass Sie &uuml;ber unser Opt-in Cookie-Banner Ihre Einwilligung hierzu im Sinne von Art. 6
            Abs. 1 lit. a DS-GVO abgegeben haben.
          </p>
        </div>
      </section>
      <section>
        <strong>9. Inhalte unserer Internetseite</strong>
        <div class="sub-content">
          <br />
          <strong>9.1 Registrierung als Benutzer</strong>
          <p>Sie haben die M&ouml;glichkeit, sich auf unserer Internetseite unter Angabe von personenbezogenen Daten zu registrieren.</p>
          <p>
            Welche personenbezogenen Daten dabei an uns &uuml;bermittelt werden, ergibt sich aus der jeweiligen Eingabemaske, die f&uuml;r
            die Registrierung verwendet wird. Die von Ihnen eingegebenen personenbezogenen Daten werden ausschlie&szlig;lich f&uuml;r die
            interne Verwendung bei uns und f&uuml;r eigene Zwecke erhoben und gespeichert. Wir k&ouml;nnen die Weitergabe an einen oder
            mehrere Auftragsverarbeiter, beispielsweise einen Paketdienstleister, veranlassen, der die personenbezogenen Daten ebenfalls
            ausschlie&szlig;lich f&uuml;r eine interne Verwendung, die uns zuzurechnen ist, nutzt.
          </p>
          <p>
            Durch eine Registrierung auf unserer Internetseite wird ferner die von Ihrem Internet-Service-Provider (ISP) vergebene
            IP-Adresse, das Datum sowie die Uhrzeit der Registrierung gespeichert. Die Speicherung dieser Daten erfolgt vor dem Hintergrund,
            dass nur so der Missbrauch unserer Dienste verhindert werden kann, und diese Daten im Bedarfsfall erm&ouml;glichen, begangene
            Straftaten aufzukl&auml;ren. Insofern ist die Speicherung dieser Daten zu unserer Absicherung erforderlich. Eine Weitergabe
            dieser Daten an Dritte erfolgt grunds&auml;tzlich nicht, sofern keine gesetzliche Pflicht zur Weitergabe besteht oder die
            Weitergabe der Strafverfolgung dient.
          </p>
          <p>
            Ihre Registrierung unter freiwilliger Angabe personenbezogener Daten dient uns au&szlig;erdem dazu, Ihnen Inhalte oder
            Leistungen anzubieten, die aufgrund der Natur der Sache nur registrierten Benutzern angeboten werden k&ouml;nnen. Registrierten
            Personen steht die M&ouml;glichkeit frei, die bei der Registrierung angegebenen personenbezogenen Daten jederzeit
            abzu&auml;ndern oder vollst&auml;ndig aus unserem dem Datenbestand l&ouml;schen zu lassen.
          </p>
          <p>
            Wir erteilen Ihnen jederzeit auf Anfrage Auskunft dar&uuml;ber, welche personenbezogenen Daten &uuml;ber Sie gespeichert sind.
            Ferner berichtigen oder l&ouml;schen wir personenbezogene Daten auf Ihren Wunsch, soweit dem keine gesetzlichen
            Aufbewahrungspflichten entgegenstehen. Ein in dieser Datenschutzerkl&auml;rung namentlich benannter Datenschutzbeauftragter und
            alle weiteren Mitarbeiter stehen der betroffenen Person in diesem Zusammenhang als Ansprechpartner zur Verf&uuml;gung.
          </p>
          <p>
            Die Verarbeitung Ihrer Datenerfolgt im Interesse einer komfortablen und einfachen Nutzung unserer Internetseite. Dies stellt ein
            berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DS-GVO dar.
          </p>
          <strong>9.2 Datenverarbeitung bei Er&ouml;ffnung eines Kundenkontos und zur Vertragsabwicklung</strong>
          <p>
            Gem&auml;&szlig; Art. 6 Abs. 1 lit. b DS-GVO werden personenbezogene Daten erhoben und verarbeitet, wenn Sie uns diese zur
            Durchf&uuml;hrung eines Vertrages oder bei der Er&ouml;ffnung eines Kundenkontos mitteilen. Welche Daten erhoben werden, ist aus
            den jeweiligen Eingabeformularen ersichtlich. Eine L&ouml;schung Ihres Kundenkontos ist jederzeit m&ouml;glich und kann durch
            eine Nachricht an die o.g. Adresse des Verantwortlichen erfolgen. Wir speichern und verwenden die von Ihnen mitgeteilten Daten
            zur Vertragsabwicklung. Nach vollst&auml;ndiger Abwicklung des Vertrages oder L&ouml;schung Ihres Kundenkontos werden Ihre Daten
            mit R&uuml;cksicht auf steuer- und handelsrechtliche Aufbewahrungsfristen gesperrt und nach Ablauf dieser Fristen gel&ouml;scht,
            sofern Sie nicht ausdr&uuml;cklich in eine weitere Nutzung Ihrer Daten eingewilligt haben oder eine gesetzlich erlaubte weitere
            Datenverwendung von unserer Seite vorbehalten wurde, &uuml;ber die wir Sie nachstehend entsprechend informieren.
          </p>
          <strong>9.3 Kontaktaufnahme / Kontaktformular</strong>
          <p>
            Im Rahmen der Kontaktaufnahme mit uns (z.B. per Kontaktformular oder E-Mail) werden personenbezogene Daten erhoben. Welche Daten
            im Falle eines Kontaktformulars erhoben werden, ist aus dem jeweiligen Kontaktformular ersichtlich. Diese Daten werden
            ausschlie&szlig;lich zum Zweck der Beantwortung Ihres Anliegens bzw. f&uuml;r die Kontaktaufnahme und die damit verbundene
            technische Administration gespeichert und verwendet. Rechtsgrundlage f&uuml;r die Verarbeitung der Daten ist unser berechtigtes
            Interesse an der Beantwortung Ihres Anliegens gem&auml;&szlig; Art. 6 Abs. 1 lit. f DS-GVO. Zielt Ihre Kontaktierung auf den
            Abschluss eines Vertrages ab, so ist zus&auml;tzliche Rechtsgrundlage f&uuml;r die Verarbeitung Art. 6 Abs. 1 lit. b DS-GVO.
            Ihre Daten werden nach abschlie&szlig;ender Bearbeitung Ihrer Anfrage gel&ouml;scht, dies ist der Fall, wenn sich aus den
            Umst&auml;nden entnehmen l&auml;sst, dass der betroffene Sachverhalt abschlie&szlig;end gekl&auml;rt ist und sofern keine
            gesetzlichen Aufbewahrungspflichten entgegenstehen.
          </p>
          <strong>9.4 Dienstleistungen / Digitale G&uuml;ter</strong>
          <p>
            Wir &uuml;bermitteln personenbezogene Daten an Dritte nur dann, wenn dies im Rahmen der Vertragsabwicklung notwendig ist, etwa
            an das mit der Zahlungsabwicklung beauftragte Kreditinstitut.
          </p>
          <p>
            Eine weitergehende &Uuml;bermittlung der Daten erfolgt nicht bzw. nur dann, wenn Sie der &Uuml;bermittlung ausdr&uuml;cklich
            zugestimmt haben. Eine Weitergabe Ihrer Daten an Dritte ohne ausdr&uuml;ckliche Einwilligung, etwa zu Zwecken der Werbung,
            erfolgt nicht.
          </p>
          <p>
            Grundlage f&uuml;r die Datenverarbeitung ist Art. 6 Abs. 1 lit. b DS-GVO, der die Verarbeitung von Daten zur Erf&uuml;llung
            eines Vertrags oder vorvertraglicher Ma&szlig;nahmen gestattet.
          </p>
        </div>
      </section>
      <section>
        <strong>10. Webanalyse</strong>
        <div class="sub-content">
          <br />
          <strong>10.1 Google Analytics</strong>
          <p>
            Auf unseren Webseiten nutzen wir Google Analytics, ein Webanalysedienst der Google Ireland Limited
            (https://www.google.de/intl/de/about/) (Gordon House, Barrow Street, Dublin 4, Irland; im Folgenden &quot;Google&quot;). In
            diesem Zusammenhang werden pseudonymisierte Nutzungsprofile erstellt und Cookies (siehe Punkt &quot;Cookies&quot;) verwendet.
            Die durch den Cookie erzeugten Informationen &uuml;ber Ihre Benutzung dieser Website wie
          </p>
          <div class="sub-content">
            <p>1. Browser-Typ/-Version,</p>
            <p>2. verwendetes Betriebssystem,</p>
            <p>3. Referrer-URL (die zuvor besuchte Seite),</p>
            <p>4. Hostname des zugreifenden Rechners (IP-Adresse),</p>
            <p>5. Uhrzeit der Serveranfrage,</p>
          </div>
          <p>
            werden an einen Server von Google in den USA &uuml;bertragen und dort gespeichert. Die Informationen werden verwendet, um die
            Nutzung der Website auszuwerten, um Reports &uuml;ber die Websiteaktivit&auml;ten zusammenzustellen und um weitere mit der
            Websitenutzung und der Internetnutzung verbundene Dienstleistungen zu Zwecken der Marktforschung und bedarfsgerechten Gestaltung
            dieser Internetseiten zu erbringen. Auch werden diese Informationen gegebenenfalls an Dritte &uuml;bertragen, sofern dies
            gesetzlich vorgeschrieben ist oder soweit Dritte diese Daten im Auftrag verarbeiten. Es wird in keinem Fall Ihre IP-Adresse mit
            anderen Daten von Google zusammengef&uuml;hrt. Die IP-Adressen werden anonymisiert, so dass eine Zuordnung nicht m&ouml;glich
            ist (IP-Masking).
          </p>
          <p>
            Sie k&ouml;nnen die Installation der Cookies durch eine entsprechende Einstellung der Browser-Software verhindern; wir weisen
            jedoch darauf hin, dass in diesem Fall gegebenenfalls nicht s&auml;mtliche Funktionen dieser Website vollumf&auml;nglich genutzt
            werden k&ouml;nnen.
          </p>
          <p>
            Diese Verarbeitungsvorg&auml;nge erfolgen ausschlie&szlig;lich bei Erteilung der ausdr&uuml;cklichen Einwilligung
            gem&auml;&szlig; Art. 6 Abs. 1 lit. a DS-GVO.
          </p>
          <p>
            Sie k&ouml;nnen dar&uuml;ber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen
            Daten (inkl. Ihrer IP-Adresse) sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie ein Browser-Add-on
            herunterladen und installieren (https://tools.google.com/dlpage/gaoptout?hl=de).
          </p>
          <p>
            Alternativ zum Browser-Add-on, insbesondere bei Browsern auf mobilen Endger&auml;ten, k&ouml;nnen Sie die Erfassung durch Google
            Analytics zudem verhindern, indem Sie auf den folgenden Link klicken: Google Analytics deaktivieren. Es wird ein Opt-out-Cookie
            gesetzt, das die zuk&uuml;nftige Erfassung Ihrer Daten beim Besuch dieser Website verhindert. Der Opt-out-Cookie gilt nur in
            diesem Browser und nur f&uuml;r unsere Website und wird auf Ihrem Ger&auml;t abgelegt. L&ouml;schen Sie die Cookies in diesem
            Browser, m&uuml;ssen Sie das Opt-out-Cookie erneut setzen.
          </p>
          <p>
            Weitere Informationen zum Datenschutz im Zusammenhang mit Google Analytics finden Sie etwa in der Google Analytics-Hilfe
            (https://support.google.com/analytics/answer/6004245?hl=de).
          </p>

          <strong>10.2 Matomo</strong>
          <p>
            Wir haben auf dieser Internetseite die Komponente Matomo des Anbieters InnoCraft Ltd., 150 Willis St, 6011 Wellington,
            Neuseeland, integriert. Matomo ist ein Softwaretool zur Web-Analyse, also zur Erhebung, Sammlung und Auswertung von Daten
            &uuml;ber das Verhalten von Besuchern von Internetseiten. Es werden dabei unter anderem Daten dar&uuml;ber erfasst, von welcher
            Internetseite eine betroffene Person auf eine Internetseite gekommen ist (sogenannter Referrer), auf welche Unterseiten der
            Internetseite zugegriffen oder wie oft und f&uuml;r welche Verweildauer eine Unterseite betrachtet wurde. Dies wird zur
            Optimierung der Internetseite und zur Kosten-Nutzen-Analyse von Internetwerbung eingesetzt.
          </p>
          <p>
            Die Software wird auf dem Server des f&uuml;r die Verarbeitung Verantwortlichen betrieben, die datenschutzrechtlich sensiblen
            Logdateien werden ausschlie&szlig;lich auf diesem Server gespeichert.
          </p>
          <p>
            Matomo setzt ein Cookie auf Ihrem IT-System. Mit der Setzung des Cookies wird uns eine Analyse der Benutzung unserer
            Internetseite erm&ouml;glicht. Durch jeden Aufruf einer der Einzelseiten dieser Internetseite wird der Internetbrowser auf Ihrem
            IT-System automatisch durch die Matomo-Komponente veranlasst, Daten zum Zwecke der Online-Analyse an unseren Server zu
            &uuml;bermitteln. Im Rahmen dieses technischen Verfahrens erhalten wir Kenntnis &uuml;ber personenbezogene Daten, wie der
            IP-Adresse der betroffenen Person, die uns unter anderem dazu dient, die Herkunft der Besucher und Klicks nachzuvollziehen.
          </p>
          <p>
            Mittels des Cookies werden personenbezogene Informationen, beispielsweise die Zugriffszeit, der Ort, von welchem ein Zugriff
            ausging und die H&auml;ufigkeit der Besuche auf unserer Internetseite gespeichert. Bei jedem Besuch unserer Internetseiten
            werden diese personenbezogenen Daten, einschlie&szlig;lich der IP-Adresse des von Ihnen genutzten Internetanschlusses, an
            unseren Server &uuml;bertragen. Diese personenbezogenen Daten werden durch uns gespeichert. Wir geben diese personenbezogenen
            Daten nicht an Dritte weiter.
          </p>
          <p>
            Sie k&ouml;nnen die Setzung von Cookies durch unsere Internetseite jederzeit mittels einer entsprechenden Einstellung des
            genutzten Internetbrowsers verhindern und damit der Setzung von Cookies dauerhaft widersprechen. Eine solche Einstellung des
            genutzten Internetbrowsers w&uuml;rde auch verhindern, dass Matomo ein Cookie auf Ihrem IT-System setzt. Zudem kann ein von
            Matomo bereits gesetzter Cookie jederzeit &uuml;ber einen Internetbrowser oder andere Softwareprogramme gel&ouml;scht werden.
          </p>
          <p>
            Ferner besteht f&uuml;r Sie die M&ouml;glichkeit, einer Erfassung der durch den Matomo erzeugten, auf eine Nutzung dieser
            Internetseite bezogenen Daten zu widersprechen und eine solche zu verhindern. Hierzu m&uuml;ssen Sie ein Opt-Out-Cookie setzen.
            Wird Ihr IT-System zu einem sp&auml;teren Zeitpunkt gel&ouml;scht, formatiert oder neu installiert, muss die betroffene Person
            erneut einen Opt-Out-Cookie setzen. Mit der Setzung des Opt-Out-Cookies besteht jedoch die M&ouml;glichkeit, dass unsere
            Internetseiten f&uuml;r Sie nicht mehr vollumf&auml;nglich nutzbar sind.
          </p>
          <p>
            Diese Verarbeitungsvorg&auml;nge erfolgen ausschlie&szlig;lich bei Erteilung der ausdr&uuml;cklichen Einwilligung
            gem&auml;&szlig; Art. 6 Abs. 1 lit. a DS-GVO.
          </p>
          <p>
            Bitte beachten Sie jedoch: Wenn Sie Ihre Zustimmung verweigern, wird Matomo dennoch von uns eingesetzt. In diesem Fall erfolgt
            die Erfassung ihrer Daten jedoch vollst&auml;ndig anonym. Es findet kein Tracking &uuml;ber mehrere Websites statt, die
            Fingerabdr&uuml;cke, d.h. die von ihnen hinterlassenen Daten, werden t&auml;glich mit einem neuen Schl&uuml;ssel
            verschl&uuml;sselt und die Daten werden f&uuml;r keine anderen Zwecke als die blo&szlig;e Analyse eines anonymen
            Nutzerverhaltens genutzt. In diesem Fall wird insbesondere auch kein Cookie auf Ihrem Rechner hinterlassen. Details k&ouml;nnen
            Sie hier einsehen: https://matomo.org/cookie-consent-banners/. In diesem Fall erfolgt die Nutzung der Daten daher auf Basis
            unseres berechtigten Interesses, gem&auml;&szlig; Art. 6 Abs. 1 f) DSGVO.<span class="Apple-converted-space">&nbsp;</span>
          </p>
          <p>
            Weitere Informationen und die geltenden Datenschutzbestimmungen von Matomo k&ouml;nnen unter https://matomo.org/privacy/
            abgerufen werden.
          </p>
        </div>
      </section>
      <section>
        <strong>11. Ihre Rechte als betroffene Person</strong>
        <div class="sub-content">
          <br />
          <strong>11.1 Recht auf Best&auml;tigung</strong>
          <p>
            Sie haben das Recht, von uns eine Best&auml;tigung dar&uuml;ber zu verlangen, ob Sie betreffende personenbezogene Daten
            verarbeitet werden.
          </p>

          <strong>11.2 Recht auf Auskunft Art. 15 DS-GVO</strong>
          <p>
            Sie haben das Recht, jederzeit von uns unentgeltliche Auskunft &uuml;ber die zu Ihrer Person gespeicherten personenbezogenen
            Daten sowie eine Kopie dieser Daten nach Ma&szlig;gabe der gesetzlichen Bestimmungen zu erhalten.
          </p>

          <strong>11.3 Recht auf Berichtigung Art. 16 DS-GVO</strong>
          <p>
            Sie haben das Recht, die Berichtigung Sie betreffender unrichtiger personenbezogener Daten zu verlangen. Ferner steht Ihnen das
            Recht zu, unter Ber&uuml;cksichtigung der Zwecke der Verarbeitung, die Vervollst&auml;ndigung unvollst&auml;ndiger
            personenbezogener Daten zu verlangen.
          </p>

          <strong>11.4 L&ouml;schung Art. 17 DS-GVO</strong>
          <p>
            Sie haben das Recht, von uns zu verlangen, dass die Sie betreffenden personenbezogenen Daten unverz&uuml;glich gel&ouml;scht
            werden, sofern einer der gesetzlich vorgesehenen Gr&uuml;nde zutrifft und soweit die Verarbeitung bzw. Speicherung nicht
            erforderlich ist.
          </p>

          <strong>11.5 Einschr&auml;nkung der Verarbeitung Art. 18 DS-GVO</strong>
          <p>
            Sie haben das Recht, von uns die Einschr&auml;nkung der Verarbeitung zu verlangen, wenn eine der gesetzlichen Voraussetzungen
            gegeben ist.
          </p>

          <strong>11.6 Daten&uuml;bertragbarkeit Art. 20 DS-GVO</strong>
          <p>
            Sie haben das Recht, die Sie betreffenden personenbezogenen Daten, welche uns durch Sie bereitgestellt wurden, in einem
            strukturierten, g&auml;ngigen und maschinenlesbaren Format zu erhalten. Sie haben au&szlig;erdem das Recht, diese Daten einem
            anderen Verantwortlichen ohne Behinderung durch uns, dem die personenbezogenen Daten bereitgestellt wurden, zu &uuml;bermitteln,
            sofern die Verarbeitung auf der Einwilligung gem&auml;&szlig; Art. 6 Abs. 1 lit. a DS-GVO oder Art. 9 Abs. 2 lit. a DS-GVO oder
            auf einem Vertrag gem&auml;&szlig; Art. 6 Abs. 1 lit. b DS-GVO beruht und die Verarbeitung mithilfe automatisierter Verfahren
            erfolgt, sofern die Verarbeitung nicht f&uuml;r die Wahrnehmung einer Aufgabe erforderlich ist, die im &ouml;ffentlichen
            Interesse liegt oder in Aus&uuml;bung &ouml;ffentlicher Gewalt erfolgt, welche uns &uuml;bertragen wurde.
          </p>
          <p>
            Ferner haben Sie bei der Aus&uuml;bung Ihres Rechts auf Daten&uuml;bertragbarkeit gem&auml;&szlig; Art. 20 Abs. 1 DS-GVO das
            Recht, zu erwirken, dass die personenbezogenen Daten direkt von einem Verantwortlichen an einen anderen Verantwortlichen
            &uuml;bermittelt werden, soweit dies technisch machbar ist und sofern hiervon nicht die Rechte und Freiheiten anderer Personen
            beeintr&auml;chtigt werden.
          </p>

          <strong>11.7 Widerspruch Art. 21 DS-GVO</strong>
          <p>
            Sie haben das Recht, aus Gr&uuml;nden, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung Sie
            betreffender personenbezogener Daten, die aufgrund von Art. 6 Abs. 1 lit. e (Datenverarbeitung im &ouml;ffentlichen Interesse)
            oder f (Datenverarbeitung auf Grundlage einer Interessenabw&auml;gung) DS-GVO erfolgt, Widerspruch einzulegen.
          </p>
          <p>Dies gilt auch f&uuml;r ein auf diese Bestimmungen gest&uuml;tztes Profiling im Sinne von Art. 4 Nr. 4 DS-GVO.</p>
          <p>
            Legen Sie Widerspruch ein, werden wir Ihre personenbezogenen Daten nicht mehr verarbeiten, es sei denn, wir k&ouml;nnen
            zwingende berechtigte Gr&uuml;nde f&uuml;r die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten
            &uuml;berwiegen, oder die Verarbeitung dient der Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen.
          </p>
          <p>
            In Einzelf&auml;llen verarbeiten wir personenbezogene Daten, um Direktwerbung zu betreiben. Sie k&ouml;nnen jederzeit
            Widerspruch gegen die Verarbeitung der personenbezogenen Daten zum Zwecke derartiger Werbung einlegen. Dies gilt auch f&uuml;r
            das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht. Widersprechen Sie gegen&uuml;ber uns der Verarbeitung
            f&uuml;r Zwecke der Direktwerbung, so werden wir die personenbezogenen Daten nicht mehr f&uuml;r diese Zwecke verarbeiten.
          </p>
          <p>
            Zudem haben Sie das Recht, aus Gr&uuml;nden, die sich aus Ihrer besonderen Situation ergeben, gegen die Sie betreffende
            Verarbeitung personenbezogener Daten, die bei uns zu wissenschaftlichen oder historischen Forschungszwecken oder zu
            statistischen Zwecken gem&auml;&szlig; Art. 89 Abs. 1 DS-GVO erfolgen, Widerspruch einzulegen, es sei denn, eine solche
            Verarbeitung ist zur Erf&uuml;llung einer im &ouml;ffentlichen Interesse liegenden Aufgabe erforderlich.
          </p>
          <p>
            Ihnen steht es frei, im Zusammenhang mit der Nutzung von Diensten der Informationsgesellschaft, ungeachtet der Richtlinie
            2002/58/EG, Ihr Widerspruchsrecht mittels automatisierter Verfahren auszu&uuml;ben, bei denen technische Spezifikationen
            verwendet werden.
          </p>

          <strong>11.8 Widerruf einer datenschutzrechtlichen Einwilligung</strong>
          <p>
            Sie haben das Recht, eine Einwilligung zur Verarbeitung personenbezogener Daten jederzeit mit Wirkung f&uuml;r die Zukunft zu
            widerrufen.
          </p>

          <strong>11.9 Beschwerde bei einer Aufsichtsbeh&ouml;rde</strong>
          <p>
            Sie haben das Recht, sich bei einer f&uuml;r Datenschutz zust&auml;ndigen Aufsichtsbeh&ouml;rde &uuml;ber unsere Verarbeitung
            personenbezogener Daten zu beschweren.
          </p>
        </div>
      </section>
      <section>
        <strong>12. Routinem&auml;&szlig;ige Speicherung, L&ouml;schung und Sperrung von personenbezogenen Daten</strong>
        <p>
          Wir verarbeiten und speichern Ihre personenbezogenen Daten nur f&uuml;r den Zeitraum, der zur Erreichung des Speicherungszwecks
          erforderlich ist oder sofern dies durch die Rechtsvorschriften, welchen unser Unternehmen unterliegt, vorgesehen wurde.
        </p>
        <p>
          Entf&auml;llt der Speicherungszweck oder l&auml;uft eine vorgeschriebene Speicherfrist ab, werden die personenbezogenen Daten
          routinem&auml;&szlig;ig und entsprechend den gesetzlichen Vorschriften gesperrt oder gel&ouml;scht.
        </p>
      </section>
      <section>
        <strong>13. Dauer der Speicherung von personenbezogenen Daten</strong>
        <p>
          Das Kriterium f&uuml;r die Dauer der Speicherung von personenbezogenen Daten ist die jeweilige gesetzliche Aufbewahrungsfrist.
          Nach Ablauf der Frist werden die entsprechenden Daten routinem&auml;&szlig;ig gel&ouml;scht, sofern sie nicht mehr zur
          Vertragserf&uuml;llung oder Vertragsanbahnung erforderlich sind.
        </p>
      </section>
      <section>
        <strong>14. Aktualit&auml;t und &Auml;nderung der Datenschutzerkl&auml;rung</strong>
        <p>Diese Datenschutzerkl&auml;rung ist aktuell g&uuml;ltig und hat den Stand: Februar 2024.</p>
        <p>
          Durch die Weiterentwicklung unserer Internetseiten und Angebote oder aufgrund ge&auml;nderter gesetzlicher beziehungsweise
          beh&ouml;rdlicher Vorgaben kann es notwendig werden, diese Datenschutzerkl&auml;rung zu &auml;ndern. Die jeweils aktuelle
          Datenschutzerkl&auml;rung kann jederzeit auf der Website unter <a href="https://cockpit.talpa.io/">cockpit</a> von Ihnen abgerufen
          und ausgedruckt werden.
        </p>
        <p>
          Diese Datenschutzerkl&auml;rung wurde mit Unterst&uuml;tzung der Datenschutz-Software:
          <a href="https://www.audatis-manager.de">audatis MANAGER</a> erstellt.
        </p>
      </section>
    </div>
  </DataProtectionStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

const DataProtectionStyled = styled('div')`
  .main {
    padding: 1rem;
    > section {
      padding-bottom: 0.5rem;
    }
    .sub-content {
      padding: 0 1.2rem;
    }
    p {
      padding: 0 1.2rem;
    }
  }
`

export default {
  components: {
    DataProtectionStyled,
  },
}
</script>
